import { theme } from '@hhgtech/hhg-components'
import styled from 'styled-components'

type Props = {
  iconSize?: number
  size?: number
  backgroundColor?: string
  borderRadius?: string
}

const StyledIconContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  width: ${(props: Props) => props.size}px;
  height: ${(props: Props) => props.size}px;
  align-items: center;
  justify-content: center;
  background-color: ${(props: Props) =>
    props.backgroundColor || theme.colors.white};
  border-radius: ${(props: Props) => props.borderRadius || '50%'};

  & > * {
    width: ${(props: Props) => props.iconSize}px;
    height: ${(props: Props) => props.iconSize}px;
  }
`

export { StyledIconContainer }
