import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import { Z_INDEX } from 'interfaces/constants'
import styled from 'styled-components'

// this is the fullsize container
export const StyledWeekOfPregnancyContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;

  ${MediaQueries.mbUp} {
    margin-top: 80px;
  }

  ${MediaQueries.mbDown} {
    margin-top: 40px;
    & > div {
      padding: 0 0;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 150%;
    height: 100%;
    background-color: ${(props: ThemeProps) =>
      props.theme.mbColors.lightOrange};
    border-radius: 100% / 30%;
    content: '';
    transform: translateX(-50%);
    ${MediaQueries.mbDown} {
      border-radius: 100% / 15%;
    }
  }
`

// content container with padding
export const StyledContentContainer = styled.div`
  position: relative;
  ${MediaQueries.mbUp} {
    padding: 120px 0px 120px;
  }

  ${MediaQueries.mbDown} {
    padding: 40px 0;
  }
`

export const StyledPillContainer = styled.div`
  display: inline-block;
  height: 120px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
  border-radius: 60px;
  cursor: pointer;

  &.selected-week-pill {
    background-color: ${(props: ThemeProps) => props.theme.mbColors.pink};

    p {
      color: ${(props: ThemeProps) => props.theme.colors.white};
    }
  }

  & > div:first-child {
    margin: 4px 4px 0;
  }

  & > p {
    margin-top: 16px;
    text-align: center;
  }
`

export const StyledWeekOfPregnancy = styled.div`
  & .title {
    text-align: center;
    &[data-space-bottom='true'] {
      padding-bottom: 168px;
    }
  }
`

export const StyledScrollContainer = styled.div`
  position: relative;
  height: 152px;
  margin-top: 16px;

  & > .slick-slider {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1032px;
    margin: auto;

    transform: translateX(-50%);
    & > .slick-arrow {
      visibility: hidden;
    }
  }

  & .centerred-pill-wrapper {
    padding: 16px 0;
    text-align: center;
  }

  & .week-pill {
    transition: all 0.4s;

    &:not(.selected-week-pill) {
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    }

    &.opacity-50 {
      opacity: 0.5;
    }

    &.opacity-10 {
      opacity: 0.1;
    }

    &.opacity-0 {
      opacity: 0;
    }
  }

  &[data-is-sticky='true'] {
    position: fixed;
    z-index: ${Z_INDEX.menuSearchPopup - 1};
    top: 68px;
    left: 0;
    width: 100vw;
    margin-top: 0;
    background-color: ${(props: ThemeProps) =>
      props.theme.mbColors.lightOrange};
    transition: all 0.2s linear;

    ${MediaQueries.mbDown} {
      top: 48px;
    }

    &[data-visible='false'] {
      top: -98px;
      ${MediaQueries.mbDown} {
        top: -170px;
      }
    }

    &[data-hideontop='true'] {
      ${MediaQueries.mbDown} {
        top: -168px;
      }
    }

    > div {
      height: 168px;
      margin-top: 0;
    }

    .centerred-pill-wrapper {
      padding: 24px 0;
    }
  }
`

export const StyledWeekDetailArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  ${MediaQueries.mbDown} {
    margin-top: 12px;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
  }

  & > img {
    width: 328px;
    margin-right: 44px;
    object-fit: contain;
    ${MediaQueries.mbDown} {
      margin-right: 0;
    }
  }

  & .detail {
    max-width: 304px;
    ${MediaQueries.mbDown} {
      margin-top: 32px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > *:not(div) {
      text-align: center;
    }

    & > button {
      width: 304px;
      margin-top: 16px;

      & > span {
        display: flex;
        justify-content: center;

        & .arrow-icon {
          margin-left: 4px;
        }
      }

      &:hover,
      &:active {
        & .arrow-icon {
          background-color: ${(props: ThemeProps) => props.theme.colors.white};
        }
      }
    }
  }
`

export const StyledInfantSizeBlock = styled.div`
  width: 304px;
  padding: 8px 16px;
  margin-top: 16px;
  background-color: rgba(251, 140, 0, 0.1);
  border-radius: 16px;

  & > div {
    display: inline-flex;
    width: 100%;
    /* justify-content: center; */
    :not(:first-child) {
      margin-top: 12px;
    }
    & > img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }
`

export const StyledStickyTopbar = styled.div`
  position: fixed;
  z-index: ${Z_INDEX.menuSearchPopup + 1};
  top: 0;
  left: 0;
  width: 100vw;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.2s linear;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: calc(var(--reading-progress) * 100%);
    height: 2px;
    background-color: #e85388;
    content: '';
    transition: width 0.2s ease;
  }

  ${MediaQueries.mbDown} {
    background-color: #e85388;
    &:after {
      display: none;
    }
  }

  &[data-visible='false'] {
    top: -70px;
  }

  .container {
    display: flex;
    align-items: center;
    padding: 21px 0;
  }

  .mobile-container {
    display: flex;
    align-items: center;
    padding: 8px 16px;
  }

  .mb-logo {
    width: auto;
    height: 28px;
    margin-right: 24px;
    cursor: pointer;
  }

  .clickable-area {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .text {
    padding: 0 12px 0 24px;
    border-left: 1px solid
      ${(props: ThemeProps) => props.theme.mbColors.lightGray};
    font-weight: 600;
  }

  .arrow {
    width: 8px;
    transition: all 0.2s ease;

    &[data-reverse='true'] {
      transform: rotateZ(-180deg);
    }
  }

  .fruit-icon {
    margin-right: 16px;
  }

  .mobile-text {
    font-weight: 600;
  }

  .mobile-arrow {
    margin-left: auto;
    transform: rotateZ(90deg);
    transition: all 0.2s ease;

    &[data-reverse='true'] {
      transform: rotateZ(-90deg);
    }
  }
`

export const StyledStickyWeekSelect = styled.div`
  position: fixed;
  z-index: ${Z_INDEX.menuSearchPopup - 1};
  top: 68px;
  left: 0;
  width: 100vw;
  background-color: ${(props: ThemeProps) => props.theme.mbColors.lightOrange};
  transition: all 0.2s linear;

  ${MediaQueries.mbDown} {
    top: 48px;
  }

  &[data-visible='false'] {
    top: -98px;
    ${MediaQueries.mbDown} {
      top: -120px;
    }
  }

  &[data-hideontop='true'] {
    ${MediaQueries.mbDown} {
      top: -168px;
    }
  }

  > div {
    height: 168px;
    margin-top: 0;
  }

  .centerred-pill-wrapper {
    padding: 24px 0;
  }
`
