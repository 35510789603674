import { Button } from '@hhgtech/hhg-components'

import { StyledLoading } from './index.styled'

export type Props = {
  size?: 'lg' | 'md' | 'sm'
  className?: string
}

const Loading = ({ className, size = 'md' }: Props) => {
  return (
    <StyledLoading className={className}>
      <Button size={size} color="transparent" isLoading>
        Loading
      </Button>
    </StyledLoading>
  )
}

export { Loading }
