import { useState, useEffect, useRef } from 'react'

import { Button, theme } from '@hhgtech/hhg-components'
import { Container } from 'components/atoms'
import { Icon } from 'components/atoms/icon'
import { Text } from 'components/atoms/text'
import { Heading } from 'components/atoms/text'
import { WeekOfPregnancyApi } from 'interfaces/types'
import { throttle } from 'lodash'
import { useIntl } from 'react-intl'
import Slider from 'react-slick'
import { CSSProperties } from 'styled-components'
import { genPropTrack, genPropTrackV2, TRACK_GA } from 'utils/tracking'

import { StyledIconContainer } from '../iconContainer'
import {
  StyledWeekOfPregnancyContainer,
  StyledContentContainer,
  StyledPillContainer,
  StyledWeekOfPregnancy,
  StyledScrollContainer,
  StyledWeekDetailArea,
  StyledInfantSizeBlock,
  StyledStickyTopbar,
  // StyledStickyWeekSelect,
} from './index.styled'

type PillProps = {
  iconSrc?: string | null
  title: string
  className?: string
  onSelect?: () => void
  weekNumber: number
}

type Props = {
  data: WeekOfPregnancyApi[]
  onSelectWeek?: (week: number) => void
  onClickReadButton?: (weekDetail: WeekOfPregnancyApi) => void
  isMobile: boolean
  className?: string
  defaultWeekIndex?: number
  withStickyScroll?: {
    scrollYFrom: number
  }
}

const Pill = ({
  iconSrc,
  title,
  className,
  onSelect,
  weekNumber,
}: PillProps) => {
  return (
    <StyledPillContainer
      {...genPropTrack(TRACK_GA.WEEK_PREGNANCY, '', {
        key: 'label',
        value: weekNumber,
      })}
      onClick={onSelect}
      className={
        className
          ? 'week-pill'.concat(' non-pointer-event-child ', className)
          : 'week-pill non-pointer-event-child'
      }
    >
      <StyledIconContainer
        size={64}
        iconSize={40}
        backgroundColor={theme.mbColors.lightOrange}
      >
        <img loading="lazy" src={iconSrc || '/icons/week_carrot.svg'} alt="" />
      </StyledIconContainer>

      <Text type="subtitle-3">{title}</Text>
    </StyledPillContainer>
  )
}

const WeekOfPregnancy = ({
  data,
  onSelectWeek,
  onClickReadButton,
  isMobile,
  className,
  defaultWeekIndex = 0,
  withStickyScroll,
}: Props) => {
  const { formatMessage: f } = useIntl()

  const [selectedWeek, setSelectedWeek] = useState<number>(defaultWeekIndex)
  const currentWeekDetail: WeekOfPregnancyApi = data[selectedWeek]

  const [readingProgress, setReadingProgress] = useState<number>(0)
  const [isStickyTopbarShown, setIsStickyTopbarShown] = useState(false)
  const [isStickyScrollShown, setIsStickyScrollShown] = useState(false)

  const getWeekNearby = (offset: number) => {
    const NUMBER_OF_WEEK = data.length
    let result = selectedWeek + offset
    if (result >= NUMBER_OF_WEEK) {
      result -= NUMBER_OF_WEEK
    } else if (result < 0) {
      result += NUMBER_OF_WEEK
    }

    return result
  }

  const generateClassName = (index: number) => {
    return index === selectedWeek
      ? 'selected-week-pill'
      : [-3, -2, -1, 1, 2, 3]
          .map((offset) => getWeekNearby(offset))
          .includes(index)
      ? undefined
      : [-4, 4].map((offset) => getWeekNearby(offset)).includes(index)
      ? 'opacity-50'
      : 'opacity-10'
  }

  const handleSelectWeek = (index: number) => {
    setSelectedWeek(index)
    if (onSelectWeek) onSelectWeek(index)
  }

  const windowScrollY = useRef<number>(0)

  useEffect(() => {
    const html = document.querySelector('html')

    const handleScroll = withStickyScroll
      ? throttle(() => {
          if (window.scrollY > windowScrollY.current) {
            if (window.scrollY >= withStickyScroll.scrollYFrom) {
              setIsStickyTopbarShown(true)
            }
          } else {
            setIsStickyTopbarShown(false)
            setIsStickyScrollShown(false)
          }

          windowScrollY.current = window.scrollY
          setReadingProgress(
            window.scrollY /
              ((html?.scrollHeight ?? 0) - (html?.clientHeight ?? 0) || 1),
          )
        }, 200)
      : () => undefined

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <StyledWeekOfPregnancyContainer className={className}>
        <Container>
          <StyledContentContainer className="main-content">
            <StyledWeekOfPregnancy>
              <Heading
                className="title"
                type={isMobile ? 'h3' : 'h2'}
                data-space-bottom={withStickyScroll && isStickyTopbarShown}
              >
                {f({
                  id: 'weekOfPregnancy.title',
                  defaultMessage: 'Week of Pregnancy',
                })}
              </Heading>

              {data.length ? (
                <StyledScrollContainer
                  data-is-sticky={withStickyScroll && isStickyTopbarShown}
                  data-visible={isStickyScrollShown}
                >
                  <Slider
                    infinite
                    swipeToSlide
                    slidesToShow={11}
                    slidesToScroll={1}
                    focusOnSelect
                    speed={300}
                    centerMode
                    beforeChange={(_, i) => handleSelectWeek(i)}
                    initialSlide={defaultWeekIndex}
                  >
                    {data.map((w, i) => (
                      <div key={i} className="centerred-pill-wrapper">
                        <Pill
                          weekNumber={i + 1}
                          className={generateClassName(i)}
                          title={f(
                            {
                              id: 'dueDateTool.weekNumber',
                              defaultMessage: 'Week {weekNumber}',
                            },
                            {
                              weekNumber: i + 1,
                            },
                          )}
                          iconSrc={w?.fruit_icon}
                        />
                      </div>
                    ))}
                  </Slider>
                </StyledScrollContainer>
              ) : undefined}

              {currentWeekDetail && (
                <StyledWeekDetailArea>
                  <img
                    loading="lazy"
                    src={currentWeekDetail?.icon || '/images/infant.png'}
                    alt="Infant"
                  />
                  <div className="detail">
                    <Heading type="h4">{currentWeekDetail?.title}</Heading>
                    <Text
                      type="bodytext-2"
                      dangerouslySetInnerHTML={{
                        __html: currentWeekDetail?.content,
                      }}
                    />

                    <StyledInfantSizeBlock>
                      <div>
                        <img
                          loading="lazy"
                          src="/icons/ruler_line.svg"
                          alt=""
                        />
                        <div>
                          <Text type="bodytext-2">
                            {f({ id: 'weekOfPregnancy.size.height' })}
                          </Text>
                          <Text type="subtitle-1">
                            {currentWeekDetail?.height || '-'} cm
                          </Text>
                        </div>
                      </div>

                      <div>
                        <img
                          loading="lazy"
                          src="/icons/scale_line.svg"
                          alt=""
                        />
                        <div>
                          <Text type="bodytext-2">
                            {f({ id: 'weekOfPregnancy.size.weight' })}
                          </Text>
                          <Text type="subtitle-1">
                            {currentWeekDetail?.weight || '-'}
                          </Text>
                        </div>
                      </div>
                    </StyledInfantSizeBlock>

                    {!!onClickReadButton && (
                      <Button
                        theme="marryBaby"
                        {...genPropTrackV2(
                          TRACK_GA.WEEK_PREGNANCY_ARTICLE,
                          '',
                          {
                            key: 'label',
                            value: selectedWeek + 1,
                          },
                        )}
                        onClick={() => onClickReadButton(currentWeekDetail)}
                        size="lg"
                        color="secondary"
                        className="btn"
                      >
                        {f({ id: 'weekOfPregnancy.readFullArticle' })}
                        <Icon
                          className="arrow-icon"
                          iconSrc="/icons/arrow-right_line.svg"
                          size={24}
                          color={theme.mbColors.pink}
                        />
                      </Button>
                    )}
                  </div>
                </StyledWeekDetailArea>
              )}
            </StyledWeekOfPregnancy>
          </StyledContentContainer>
        </Container>
      </StyledWeekOfPregnancyContainer>

      {withStickyScroll && (
        <>
          <StyledStickyTopbar
            data-visible={isStickyTopbarShown}
            style={
              {
                '--reading-progress': readingProgress,
              } as CSSProperties
            }
          >
            {isMobile ? (
              <div
                className="mobile-container"
                onClick={() => setIsStickyScrollShown((s) => !s)}
              >
                <StyledIconContainer
                  iconSize={24}
                  size={32}
                  backgroundColor={theme.mbColors.lightOrange}
                  className="fruit-icon"
                >
                  <img
                    loading="lazy"
                    src={
                      currentWeekDetail?.fruit_icon || '/icons/week_carrot.svg'
                    }
                    alt=""
                  />
                </StyledIconContainer>
                <Text
                  type="subtitle-2"
                  color={theme.colors.white}
                  className="mobile-text"
                >
                  {currentWeekDetail?.title}
                </Text>
                <Icon
                  className="mobile-arrow"
                  color={theme.colors.white}
                  iconSrc={'/icons/chevron.svg'}
                  size={18}
                  data-reverse={isStickyScrollShown}
                />
              </div>
            ) : (
              <Container className="container">
                <img
                  loading="lazy"
                  className="mb-logo"
                  src="/images/mb-logo.svg"
                  alt=""
                  onClick={() =>
                    (window.location.href = window.location.origin)
                  }
                />
                <div
                  className="clickable-area"
                  onClick={() => setIsStickyScrollShown((s) => !s)}
                >
                  <Text className="text" type="subtitle-2">
                    {f({ id: 'weekOfPregnancy.title' })}:{' '}
                    {currentWeekDetail?.title}
                  </Text>
                  <img
                    loading="lazy"
                    className="arrow"
                    data-reverse={isStickyScrollShown}
                    src={'/svg/arrow.svg'}
                    alt=""
                  />
                </div>
              </Container>
            )}
          </StyledStickyTopbar>
        </>
      )}
    </>
  )
}

export { WeekOfPregnancy }
