import { useEffect, useState } from 'react'

import Cookies from 'js-cookie'

export const useCheckCloseBanner = (cookieName: string) => {
  const [closeSiteWideCookie, setCloseSiteWideCookie] = useState(false)

  useEffect(() => {
    setCloseSiteWideCookie(Cookies.get(cookieName) === 'true')
  }, [cookieName])
  return closeSiteWideCookie
}
