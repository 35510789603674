interface ITrack {
  category: string
  action: string
  label: string
}

interface ITRACK_GA {
  [key: string]: ITrack
}

interface ISUB_TRACK {
  value: string | number | boolean
  key: keyof ITrack
}

export type IResultDataTrack = IResultDataTrackV1 | IResultDataTrackV2

type IResultDataTrackV1 = {
  'data-event-category': string
  'data-event-action': string
  'data-event-label': string
}

type IResultDataTrackV2 = {
  dataEventCategory: string
  dataEventAction: string
  dataEventLabel: string
}

const genDataTrack = (code: ITrack, link: string, subTrack?: ISUB_TRACK) => {
  const param = { ...code }
  if (subTrack?.key) {
    param[subTrack.key] = (param[subTrack.key] as string).replace(
      '{value}',
      subTrack.value as string,
    )
  }
  return { ...param, label: link || param.label }
}

export const genPropTrack = (
  ...props: [ITrack, string, ISUB_TRACK?]
): IResultDataTrackV1 => {
  const { category, label, action } = genDataTrack(...props)

  return {
    'data-event-category': category,
    'data-event-action': action,
    'data-event-label': label,
  }
}

export const genPropTrackV2 = (
  ...props: [ITrack, string, ISUB_TRACK?]
): IResultDataTrackV2 => {
  const { category, label, action } = genDataTrack(...props)

  return {
    dataEventCategory: category,
    dataEventAction: action,
    dataEventLabel: label,
  }
}

export const TRACK_GA: ITRACK_GA = {
  LOGIN: {
    category: 'Sign Up',
    action: 'Button Click',
    label: 'Page URL',
  },
  CONTINUE_EMAIL: {
    category: 'Sign Up',
    action: 'Continue with Email',
    label: 'Page URL',
  },
  CONTINUE_FB: {
    category: 'Sign Up',
    action: 'Continue with Facebook',
    label: 'Page URL',
  },
  BOOK_MARK: {
    category: 'Bookmark',
    action: 'Click',
    label: 'Page URL',
  },
  HAMBURGER_LINK: {
    category: 'Hamburger Navigation',
    action: 'Click',
    label: 'Link URL',
  },
  TREND: {
    category: 'Trending',
    action: 'Click',
    label: 'Link URL',
  },
  LATEST_NEWS: {
    category: 'Latest News',
    action: 'Click',
    label: 'Link URL',
  },
  ARTICLE: {
    category: 'Top Article Banner',
    action: 'Click {value}', // Handle
    label: 'Link URL',
  },
  CATEGORY: {
    category: 'Category Menu',
    action: 'Click',
    label: 'Link URL',
  },
  DUE_DATE: {
    category: 'Due Date Tool',
    action: 'Submit',
    label: 'Page URL',
  },
  SUBSCRIBE_REGISTRATION: {
    category: 'Due Date Tool',
    action: 'Submit',
    label: 'Page URL',
  },
  WEEK_PREGNANCY: {
    category: 'Week of Pregnancy Slider',
    action: 'Click Pill',
    label: 'Week {value}', // Handle
  },
  //!WEEK_PREGNANCY   Push a custom event to dataLayer when a slider swipe event is triggered
  // <script>
  //     $('#slider').on('swipe', function (event, slick, direction) {
  //         dataLayer.push({"event": "sliderSwipe", "swipeDirection": direction});
  //     });
  // </script>
  WEEK_PREGNANCY_ARTICLE: {
    category: 'Week of Pregnancy Slider',
    action: 'Click Article',
    label: 'Week {value}', // Handle
  },
  ARTICLE_INTERNAL_LINK: {
    category: 'Internal Link Click on Article',
    action: 'Click Text',
    label: 'Destination Link',
  },
  RELATE_ARTICLE_INTERNAL_LINK: {
    category: 'Related Article',
    action: 'Click 1, 2, 3', // Handle
    label: 'Destination Link',
  },
  FEEDBACK: {
    category: 'FeedbackIcon',
    action: 'No', // Handle
    label: 'Page URL',
  },
  SHARE: {
    category: 'Share Icon',
    action: 'Click',
    label: 'Page URL',
  },
  COMMENT: {
    category: 'Comments',
    action: 'Icon Click',
    label: 'Page URL',
  },
  CHANGE_ICON: {
    category: 'Font Size Change Icon',
    action: 'Click',
    label: 'Page URL',
  },
  ARTICLE_BANNER: {
    category: 'Top Article Banner',
    action: 'Click 1, 2, 3...', //handle
    label: 'Link URL',
  },
  WRITE_POST: {
    category: 'Together',
    action: 'Write Post Click',
    label: 'Page URL',
  },
  DISCUSS_POST: {
    category: 'Together Navigation',
    action: 'Discussion Click',
    label: 'Page URL',
  },
  TOPIC_POST: {
    category: 'Together Navigation',
    action: 'Topic Click',
    label: 'Page URL',
  },
  ABOUT_POST: {
    category: 'Together Navigation',
    action: 'About Click',
    label: 'Page URL',
  },
  TOGETHER_HOME: {
    category: 'Together Menu Bar',
    action: 'Home Click',
    label: 'Page URL',
  },
  TOGETHER_COMMUNITY: {
    category: 'Together Menu Bar',
    action: 'Community Click',
    label: 'Page URL',
  },
  TOGETHER_POST: {
    category: 'Together Menu Bar',
    action: 'Write Post Click',
    label: 'Page URL',
  },
  TOGETHER_MY_POST: {
    category: 'Together Menu Bar',
    action: 'My Post Click',
    label: 'Page URL',
  },
  TOGETHER_SHARE: {
    category: 'Together Menu Bar',
    action: 'Share Click',
    label: 'Page URL',
  },
  TOGETHER_JOIN_COMMUNITY: {
    category: 'Together',
    action: 'Share Click',
    label: 'Page URL',
  },
  TOGETHER_COMMUNITY_CLICK: {
    category: 'Together',
    action: 'Like Click',
    label: 'Page URL',
  },
  TOGETHER_COMMUNITY_COMMEND: {
    category: 'Together',
    action: 'Comment Click',
    label: 'Page URL',
  },
  TOGETHER_COMMUNITY_SHARE: {
    category: 'Together',
    action: 'Share Click',
    label: 'Page URL',
  },
}
