import styled from 'styled-components'

type Props = {
  iconSrc: string
  color?: string
  size?: number
}

const StyledIcon = styled.span`
  display: inline-block;
  overflow: hidden;

  width: ${(props: Props) => props.size}px;
  height: ${(props: Props) => props.size}px;

  background-color: ${(props: Props) => props.color};
  background-repeat: no-repeat;

  -webkit-mask-image: url(${(props: Props) => props.iconSrc});
  mask-image: url(${(props: Props) => props.iconSrc});
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
`

export { StyledIcon }
