import { Button } from '@hhgtech/hhg-components'
import type { Props as IButtonProps } from '@hhgtech/hhg-components/build/components/atoms/button'
import styled from 'styled-components'

import { StyledButton } from './index.styled'

const ButtonStyled = styled(Button)`
  border-color: ${(props) => props.theme.mbColors.pink} !important;
  border-radius: 16px;
  color: ${(props) => props.theme.colors.white} !important;
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.mbColors.pink} !important;
  }

  &[data-color='secondary'] {
    color: ${(props) => props.theme.mbColors.pink} !important;
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors.white} !important;
    }
  }
  & > div {
    position: relative;
  }
`

const ButtonPink = (props: IButtonProps) => {
  const { children, ...others } = props
  return (
    <ButtonStyled color="primary" {...others}>
      {children}
    </ButtonStyled>
  )
}
const ButtonTertiary = (props: IButtonProps) => {
  const { children, ...others } = props
  return (
    <ButtonStyled color="secondary" {...others}>
      {children}
    </ButtonStyled>
  )
}

export { StyledButton, ButtonPink, ButtonTertiary }
