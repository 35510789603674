import { Button, ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  border-radius: 16px;

  &[data-color='primary'] {
    &:not(:disabled) {
      &,
      &:active,
      &:hover {
        background-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
      }
    }

    &[data-size='lg'] {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &[data-color='secondary'] {
    border-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    color: ${(props: ThemeProps) => props.theme.mbColors.pink};

    &:hover,
    &:active {
      border-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
      background-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    }

    &[data-size='lg'] {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  &[data-color='tertiary'] {
    border-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    color: ${(props: ThemeProps) => props.theme.mbColors.pink};
  }
`
